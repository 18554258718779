<template>
    <BaseSection
        width="page"
        class="section--text-image"
    >
        <BaseColumns :is-reversed="imageAlignment === 'left'">
            <template #column-text>
                <RichText :content="richTextWithTitle" />

                <template v-if="callToAction && (callToAction.url || callToAction.element)">
                    <BaseCallToAction
                        :link="callToAction"
                        class="text-image__call-to-action"
                    />
                </template>
            </template>

            <template #column-image>
                <BaseImage
                    v-if="singleImage"
                    :src="singleImage.w768"
                    :src-tiny="singleImage.w32"
                    :srcset="`
                        ${singleImage.w320} 320w,
                        ${singleImage.w768} 768w,
                        ${singleImage.w980} 980w,
                        ${singleImage.w1260} 1260w
                    `"
                    sizes="
                        (max-width: 380px) 320px,
                        768px
                    "
                    :width="singleImage.width"
                    :height="singleImage.height"
                    :title="singleImage.title"
                    :alt="singleImage.alt"
                    :caption="singleImage.caption"
                    :credits="singleImage.credits"
                />
            </template>
        </BaseColumns>
    </BaseSection>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: null
    },
    richText: {
        type: String,
        default: null
    },
    image: {
        type: Array,
        default: null
    },
    imageAlignment: {
        type: String,
        default: null
    },
    callToAction: {
        type: Object,
        default: null
    }
});

const singleImage = ref(props.image && props.image[0] ? props.image[0] : null);

const richTextWithTitle = computed(() => {
    if (!props.title && !props.richText) {
        return;
    }

    if (!props.richText) {
        return `<h2>${props.title}</h2>`;
    }

    if (!props.title) {
        return props.richText;
    }

    return `<h2>${props.title}</h2>${props.richText}`;
});
</script>

<style lang="less">
.text-image__call-to-action {
    margin-top: var(--spacing-sm);

    @media @q-md-min {
        margin-top: var(--spacing-md);
    }
}
</style>
